
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ChakraProvider } from '@chakra-ui/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import Maintenance from '../components/Maintenance/Maintenance';
import { Provider } from '../hooks/useClient';
import { useForceUpdate } from '../hooks/useForceUpdate';
import customTheme from '../styles/index';
import '../styles/styles.scss';
import { AuthProvider } from '../utils/AuthContext';
import { pageView, setup } from '../utils/eventTracking/ga';
import { supabase } from '../utils/supabaseClient';
config.autoAddCss = false;
const App = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();
    const forceUpdate = useForceUpdate();
    const isInMaintenance = process.env.NEXT_PUBLIC_IS_IN_MAINTENANCE === 'true';
    useEffect(() => {
        setup();
    }, []);
    useEffect(() => {
        pageView(router.pathname);
    }, [router.pathname]);
    useEffect(() => {
        forceUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.locale]);
    /// ----------Render-------------
    return (<>
      <Head>
        <meta name='google-site-verification' content='4yg08zeV4bzwZG6PSFkQsGqhy0SEetksInmDv_P2VxM'/>
        <title>Cedille AI</title>
      </Head>
      <Provider value={supabase}>
        <AuthProvider>
          <ChakraProvider theme={customTheme}>
            {isInMaintenance ? (<MainLayout>
                <Maintenance />
              </MainLayout>) : (<MainLayout>
                <Component {...pageProps}/>
              </MainLayout>)}
          </ChakraProvider>
        </AuthProvider>
      </Provider>
    </>);
};
const __Next_Translate__Page__18fe3fcf603__ = App;

    export default __appWithI18n(__Next_Translate__Page__18fe3fcf603__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  